export const sentencesList = [
  "Aller a quelqu’un comme un gant",
  "Appeler un chat un chat",
  "Apres la pluie, le beau temps",
  "Au bout de son rouleau",
  "Au bout du tunnel",
  "Au pied de la lettre",
  "Au royaume des aveugles, les borgnes sont rois",
  "Autant chercher une aiguille dans une botte de foin",
  "Avoir bon pied bon oeil",
  "Avoir des fourmis dans les jambes",
  "Avoir du front tout le tour de la tete",
  "Avoir du pain sur la planche",
  "Avoir beaucoup de travail, de taches a accomplir",
  "Avoir l’estomac dans les talons",
  "Avoir l’oreille fine",
  "Avoir la chair de poule",
  "Avoir la grosse tete",
  "Avoir le beurre et l’argent du beurre",
  "Avoir le compas dans l’oeil",
  "Bavard comme une pie",
  "Bailler à s’en decrocher la machoire",
  "Boire comme une eponge",
  "Bouche cousue!",
  "C’est du gateau",
  "C’est en forgeant qu’on devient forgeron",
  "C’est la ou le bat blesse",
  "Casser les pieds a quelqu’un",
  "Changer un cheval borgne pour un cheval aveugle",
  "De fil en aiguille",
  "Debarrasser le plancher",
  "Devoiler le pot aux roses",
  "Donner un coup de poignard dans le dos",
  "En avril, ne te decouvre pas d’un fil; en mai, fais ce qu’il te plait",
  "En faire tout un plat",
  "Enfoncer une porte ouverte",
  "Etre comme les deux doigts de la main",
  "Etre dans une impasse",
  "Etre la cinquieme roue du carrosse",
  "Etre ne sous une bonne etoile",
  "Faire chou blanc",
  "Faire contre mauvaise fortune bon coeur",
  "Faire d’une pierre deux coups",
  "Faire des yeux de velours a quelqu’un",
  "Faire la pluie et le beau temps",
  "Faire mouche",
  "Fermer les yeux sur quelque chose",
  "Gai comme un pinson",
  "Humeur de chien",
  "Il n’y a pas de fumee sans feu",
  "Il pleut/tombe des clous",
  "Il y a anguille sous roche",
  "Jeter l’argent par les fenetres",
  "Les cordonniers sont toujours les plus mal chausses",
  "Marcher comme sur des roulettes",
  "Mettre des batons dans les roues",
  "Mettre la puce à l’oreille",
  "Mettre le doigt sur quelque chose",
  "Mettre tous ses oeufs dans le meme panier",
  "Mon petit doigt me l’a dit",
  "Ne pas avoir la langue dans sa poche",
  "Ne pas y aller par quatre chemins",
  "Ne tenir qu’a un fil",
  "Passer l’eponge sur quelque chose",
  "Payer rubis sur l’ongle",
  "Quand on parle du loup, on en voit la queue",
  "Qui se ressemble s’assemble",
  "Rendre la monnaie de sa piece",
  "Se cogner/Se taper la tete contre les murs",
  "Se jeter dans la gueule du loup",
  "Taper dans le mille",
  "Tater le terrain",
  "Tendre l’autre joue",
  "Tenir le crachoir",
  "Tirer son chapeau a quelqu’un",
  "Tire a quatre epingles",
  "Toucher du bois",
  "Tourner autour du pot",
  "Un tiens vaut mieux que deux tu l’auras"
];