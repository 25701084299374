export const image = [
  '0 0px',
  '0 -150px',
  '0 -300px',
  '0 -450px',
  '0 -600px',
  '0 -750px',
  '0 -900px',
  '0 -1050px',
  '0 -1200px',
  '0 -1350px',
  '0 -1500px',
  '0 -1650px',
  '0 -1800px',
  '0 -1950px'
];